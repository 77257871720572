import { ActiveUser } from "./models/activeUser";
import { Log } from "./models/log";
import { PaginatedList } from "./models/paginatedList";
import { AccessArray, User } from "./models/user";
import { ShortUser } from "./models/shortUser";
import { Config } from "./models/config";

export type State = {
  user: User;
  config: Config;

  // lookup
  users: Array<User>;
  logEvents: Array<string>;

  shortUsers: Array<ShortUser>;
  activeUsers: Array<ActiveUser>;
  log: PaginatedList<Log>;
};

export const state: State = {
  user: sessionStorage.getItem('token') ? restoreUser() : new User(null),
  config: getConfig(),

  // lookup
  users: new Array<User>(),
  logEvents: new Array<string>(),

  shortUsers: new Array<ShortUser>(),
  activeUsers: new Array<ActiveUser>(),
  log: new PaginatedList<Log>()
};

function restoreUser():User{
  const aar = getFromStorage('aar');
  const userFromStorage:User = {
    accessArray: buildAccessArray(aar),
    id:  getFromStorage('userId'),
    name:  getFromStorage('userName'),
    initials: getFromStorage('initials'),
    email:  getFromStorage('userEmail'),
    levelId: getFromStorage('levelId'),
    levelName:  getFromStorage('levelName'),
    token:  getFromStorage('token'),
    unreadCount:  parseInt(getFromStorage('unreadCount')),
    phone: '',
    active: true
  }
  return userFromStorage;
}

function getFromStorage(key:string): string{
  const val = sessionStorage.getItem(key);
  let retVal = "";
  if(val != null){
    retVal = val;
  }
  return retVal;
}

function buildAccessArray(aar:string):AccessArray{
  const aarArr = aar.split(',');
  return {
    edycja_profilu: parseInt(aarArr[0]) > 0 ? true : false,
    menu_archiwumZlecen: parseInt(aarArr[1]) > 0 ? true : false,
    menu_klienci: parseInt(aarArr[2]) > 0 ? true : false,
    menu_listaZlecen: parseInt(aarArr[3]) > 0 ? true : false,
    menu_logSystemu: parseInt(aarArr[4]) > 0 ? true : false,
    menu_media: parseInt(aarArr[5]) > 0 ? true : false,
    menu_noweZlecenie: parseInt(aarArr[6]) > 0 ? true : false,
    menu_statystykiDruku: parseInt(aarArr[7]) > 0 ? true : false,
    menu_uzytkownicy: parseInt(aarArr[8]) > 0 ? true : false,
    menu_wiadomosci: parseInt(aarArr[9]) > 0 ? true : false,
    menu_wycena: parseInt(aarArr[10]) > 0 ? true : false,
    zlecenie_drukowanie: parseInt(aarArr[11]) > 0 ? true : false,
    zlecenie_wykonczenie: parseInt(aarArr[12]) > 0 ? true : false,
    zlecenie_zakonczenie: parseInt(aarArr[13]) > 0 ? true : false,
    zmiana_hasla: parseInt(aarArr[14]) > 0 ? true : false
  };
}

function getConfig(){
  const config: Config = {} as Config;
  config.itemsPerPage = [15,25,50,100,150,200,300,500];
  return config;
}