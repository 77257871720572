import { ActionTree, ActionContext } from "vuex";
import { Api } from "./apiUrls";
import { State } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import { RootState } from "@/store/root-state";
import { AxiosInstance } from "axios";
import axios from "@/axios-instance";
import qs from "qs";
import { User } from "./models/user";
import { ActiveUser } from "./models/activeUser";
import { PaginatedList } from "./models/paginatedList";
import { Log } from "./models/log";
import { LogFilters } from "./models/logFilters";

const apiAxios: AxiosInstance = axios.$axios;
const currentUserId = () => { return sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : '' };
const simpleParams = (action: string) => { return {params: {id: currentUserId(), action: action}} }; 

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  // login
  [ActionTypes.LOGIN]({ commit }: AugmentedActionContext, payload: { username: string, password: string }): Promise<void>;
  [ActionTypes.LOGOUT]({ commit }: AugmentedActionContext): void;

  // lookup
  [ActionTypes.GET_USERS]({ commit }: AugmentedActionContext): Promise<void>;
  [ActionTypes.GET_LOG_EVENTS]({ commit }: AugmentedActionContext): Promise<void>;

  // heartbeat
  [ActionTypes.HEARTBEAT]({ commit }: AugmentedActionContext): Promise<void>;
  [ActionTypes.REPORT_STATE]({ commit }: AugmentedActionContext, payload: { currentState: string }): Promise<void>;
  [ActionTypes.UPDATE_STATES]({ commit }: AugmentedActionContext, payload: { activeUsers: Array<ActiveUser> }): void;

  // log
  [ActionTypes.GET_LOG]({ commit }: AugmentedActionContext, payload: { filters: LogFilters }): Promise<void>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.LOGIN]({ commit }, { username, password }) {
    const user:User = (await apiAxios.post(Api.loginApi, {username, password}, { headers: { 'Content-Type': 'application/json' }})).data;
    commit(MutationTypes.SET_USER, user);
  },
  [ActionTypes.LOGOUT]({ commit }) {
    commit(MutationTypes.SET_USER, null);
  },
  async [ActionTypes.GET_USERS]({ commit }){
    const users:Array<User> = (await apiAxios.get(Api.usersApi, simpleParams('getUsers'))).data;
    commit(MutationTypes.SET_USERS, users);
  },
  async [ActionTypes.GET_LOG_EVENTS]({ commit }) {
    const logEvents:Array<string> = (await apiAxios.get(Api.logApi, simpleParams('getLogEvents'))).data;
    commit(MutationTypes.SET_LOG_EVENTS, logEvents);
  },
  async [ActionTypes.HEARTBEAT]({ commit }) {
    const activeUsers:Array<ActiveUser> = (await apiAxios.get(Api.usersApi, simpleParams('heartbeat'))).data;
    commit(MutationTypes.HEARTBEAT, activeUsers);
  },
  async [ActionTypes.GET_LOG]({ commit }, { filters }) {
    const log:PaginatedList<Log> = (await apiAxios.get(Api.logApi, { params: {id: currentUserId(), action: 'getLogs', filter: filters }})).data;
    commit(MutationTypes.SET_LOG, log);
  },
  async [ActionTypes.REPORT_STATE]({ commit }, { currentState }) {
    await apiAxios.post(Api.usersApi, { id: currentUserId(), currentState: currentState, action: "reportState" }, { headers: { 'Content-Type': 'application/json' }});
  },
  [ActionTypes.UPDATE_STATES]({ commit }, { activeUsers }){
    commit(MutationTypes.HEARTBEAT, activeUsers);
  }
};


/*const user:any = { 
      accessArray:  {
        edycja_profilu: "1",
        menu_archiwumZlecen: "0",
        menu_klienci: "1",
        menu_listaZlecen: "0",
        menu_logSystemu: "1",
        menu_media: "1",
        menu_noweZlecenie: "1",
        menu_statystykiDruku: "1",
        menu_uzytkownicy: "1",
        menu_wiadomosci: "1",
        menu_wycena: "1",
        zlecenie_drukowanie: "1",
        zlecenie_wykonczenie: "1",
        zlecenie_zakonczenie: "1",
        zmiana_hasla: "1"
      },
      initials: "FL",
      level_guid: "6A9287C1-FBBC-49CB-8DB0-6E0A5BDC0A74",
      level_name: "Administrator",
      token: "b8VCQM1ZvSs6Ct/hrp1p8cJW98sXG1R0GH+RYP6X/hnXtCGiu4+fOLM65JsM+kGV57xvhjpoGS8Z3pKBtj/lQY4R9QZpHELNOi7LjrIqQDTDzMdahen3Ss8b+5hYw6iGorYDSj3kWsuej4HRpdELtCnoSe91DNfbRxrnyyPaSQ52soVcUvZRgixLO6t4gA==",
      unreadCount: "0",
      user: "filip.lenkiewicz@output42.com",
      user_guid: "CACC70AB-20AA-42B0-9F52-6AF2B78CE5FA",
      username: "Filip Lenkiewicz"
    }
    const promise = new Promise<User>(resolve => {
      setTimeout(() => { commit(MutationTypes.SET_USER, user); resolve(user); }, 2000);
    });
    return promise;*/


    /*const activeUsers:Array<ActiveUser> = [{
      initials: "FL",
      last_activity:"1687324770",
      last_seen_state:"zamowienia",
      name: "Filip Lenkiewicz",
      user_guid: "CACC70AB-20AA-42B0-9F52-6AF2B78CE5FA"
    },
    {
      initials: "JL",
      last_activity:"1687325070",
      last_seen_state:"zamowienia",
      name: "Joanna Lenkiewicz",
      user_guid: "CACC70AB-20AA-42B0-9F52-6AF2B78CE5FB"
    }];
    const promise = new Promise<Array<ActiveUser>>(resolve => {
      setTimeout(() => { commit(MutationTypes.HEARTBEAT, activeUsers); resolve(activeUsers); }, 500);
    });*/