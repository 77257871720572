import { PaginationData } from "./paginationData";

export class LogFilters {
    constructor() {
        this.pagination = new PaginationData();
        this.forUser = "";
        this.logEvent = "";
        this.dateFrom = "";
        this.dateTo = "";
        this.searchPhrase = "";
    }

    pagination: PaginationData;
    forUser: string;
    logEvent: string;
    dateFrom: string;
    dateTo: string;
    searchPhrase: string;
}