import { MutationTree } from "vuex";
import { store } from "@/store";
import { MutationTypes } from "./mutation-types";
import { State } from "./state";
import { User } from "./models/user";
import * as routerFn from '@/router';
import { ActiveUser } from "./models/activeUser";
import { PaginatedList } from "./models/paginatedList";
import { Log } from "./models/log";
import Bus from '../../eventBus';
const emitter = Bus.getBus();

// GoTo
const goTo = (state: string, params?:any) => { return routerFn.goTo(state, params); }

export type Mutations<S = State> = {
  [MutationTypes.SET_USER](state: S, data: User | null): void;
  [MutationTypes.HEARTBEAT](state: S, data: Array<ActiveUser>): void;
  [MutationTypes.SET_LOG](state: S, data: PaginatedList<Log>): void;

  //lookup
  [MutationTypes.SET_USERS](state: S, data: Array<User>): void;
  [MutationTypes.SET_LOG_EVENTS](state: S, data: Array<string>): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_USER](state, data) {
    if(data){
      store.state.speedprint.user = data;
      let str = "";
      const arr = Object.values(data.accessArray);
      const valArr = arr.forEach((el, i) => {
        str += el == "1" ? (Math.random() * (9 - 1) + 1) : 0;
        str += i < arr.length ? "," : "";
      });
      sessionStorage.setItem('aar', str);
      sessionStorage.setItem('initials', data.initials);
      sessionStorage.setItem('levelId', data.levelId);
      sessionStorage.setItem('levelName', data.levelName);
      sessionStorage.setItem('token', data.token); 
      sessionStorage.setItem('unreadCount', data.unreadCount.toString());
      sessionStorage.setItem('userEmail', data.email);
      sessionStorage.setItem('userId', data.id);
      sessionStorage.setItem('userName', data.name);
      emitter.emit('login', data);
      goTo('dashboard','zlecenia');
    } else {
      sessionStorage.clear();
      goTo('login');
    }
  },
  [MutationTypes.HEARTBEAT](state, data:Array<ActiveUser>){
    store.state.speedprint.activeUsers = data;
  },
  [MutationTypes.SET_LOG](state, data:PaginatedList<Log>){
    store.state.speedprint.log = data;
  },
  [MutationTypes.SET_USERS](state, data){
    store.state.speedprint.users = data;
  },
  [MutationTypes.SET_LOG_EVENTS](state, data){
    store.state.speedprint.logEvents = data;
  }
};
