import { RootState } from "../root-state";
import { GetterTree } from "vuex";
import { State } from "./state";

export type Getters = {
  isAuth(state: State): () => boolean;
};

export const getters: GetterTree<State, RootState> & Getters = {
  isAuth: (state) => () => { return (sessionStorage.getItem('userId') != null && sessionStorage.getItem('token') != null) },
  //getOwnership:(state) => (ownershipId) => state.config.dictionaries.ownership[ownershipId]
};
