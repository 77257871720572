import { createStore, createLogger } from "vuex";
import { store as speedprint, SpeedPrintStore } from "./speedprint";
import { RootState } from "./root-state";

export type Store = SpeedPrintStore<Pick<RootState, "speedprint">>;

// Plug in logger when in development environment
const debug = process.env.NODE_ENV !== "production";
const plugins = debug ? [createLogger({})] : [];

export const store = createStore({ plugins, modules: { speedprint } });

export function useStore(): Store {
  return store as Store;
}
