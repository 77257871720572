import axios, { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import app_version from "./app-version";
import { goTo } from "./router";
import Bus from './eventBus';
const emitter = Bus.getBus();

const createAxios = (baseURL: string, headers: string) => {
  const newInstance = axios.create({
    baseURL: baseURL,
    headers: {
      Accept: headers,
      AppVersion: app_version,
    },
  });

  newInstance.interceptors.request.use(
    (config) => {
      const token = sessionStorage.getItem('token');
      if(token){
        config.headers['Authorization'] = "Bearer " + token;
        config.headers['Content-Type'] = "application/json";
      }
      return config;
    }
  )

  newInstance.interceptors.response.use(
    (response) => response,
    ({ response }) => {
      if(response){
        if(response.status == 500){
          console.log(response);
          if(response.data.respType == "error-log-out"){
            emitter.emit('logout');
            return false;
          }
        }
        return Promise.reject(response.data);
      }
    }
  );
  return newInstance;
};

const $axios: AxiosInstance = createAxios("api/", "application/json");
$axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, {
    arrayFormat: "repeat",
    encode: true,
    allowDots: true,
    skipNulls: true,
  });
};
export default { $axios };
