import mitt, { Emitter } from "mitt";

class Bus {
    private static instance: Emitter<Record<string,unknown>>;

    private constructor() { }

    public static getBus(): Emitter<Record<string,unknown>>{
        if(!Bus.instance) {
            Bus.instance = mitt();
        }
        return Bus.instance;
    }
}

export default Bus;