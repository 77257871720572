import { createRouter, createWebHistory } from "vue-router";
import { useStore } from "./store";
import { ActionTypes } from "./store/speedprint/action-types";
import Login from "./components/Login.vue";
import NotFound from "./components/NotFound.vue";
import Dashboard from "./components/Dashboard.vue";
import Clients from "./components/Clients.vue";
import Users from "./components/Users.vue";
import Log from "./components/Log.vue";
import Media from "./components/Media.vue";
import Stats from "./components/Stats.vue";
import Messages from "./components/Messages.vue";

/* Routes -------------- */
const routes = [
  {
    path: "/",
    component: Dashboard,
    name: "dashboard",
  },
  {
    path: "/login",
    component: Login,
    name: "login",
  },
  {
    path: "/klienci",
    component: Clients,
    name: "clients",
  },
  {
    path: "/uzytkownicy",
    component: Users,
    name: "users",
  },
  {
    path: "/statystyki",
    component: Stats,
    name: "stats",
  },
  {
    path: "/media",
    component: Media,
    name: "media",
  },
  {
    path: "/log",
    component: Log,
    name: "log",
  },
  {
    path: "/wiadomosci",
    component: Messages,
    name: "messages",
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
    name: "notfound",
  }
  /* newOrder
     pricing
     archive*/
];
/* END OF: Routes ------- */

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem("token");
  if (to.name !== "login" && !token) next({name: "login"});
  else next();
});

export default router;

// GoTo
export function goTo(state: string, stateToReport:string | null = null, params:any | null = null){ 
  if(!params) {
      router.push({ name: state });
  } else {
      router.push({ name: state, params: params });
  }
  if(stateToReport) reportState(stateToReport);
}

// Report state
const reportState = async (state:string) => {
  const store = useStore();
  await store.dispatch(ActionTypes.REPORT_STATE, {currentState: state});
}
