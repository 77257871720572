export enum MutationTypes {
  SET_USER = "SET_USER",
  HEARTBEAT = "HEARTBEAT",

  // lookup
  SET_USERS = "SET_USERS",
  SET_LOG_EVENTS = "SET_LOG_EVENTS",

  // log
  SET_LOG = "SET_LOG"
}
