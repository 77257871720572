export class PaginationData {
    constructor(){
        this.pageNumber = 1;
        this.totalPages = 1;
        this.totalCount = 0;
        this.itemsPerPage = 15;
        this.hasPreviousPage = false;
        this.hasNextPage = false;
    }

    pageNumber: number;
    totalPages: number;
    totalCount: number;
    itemsPerPage: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
}