export enum ActionTypes {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  HEARTBEAT = "HEARTBEAT",
  REPORT_STATE = "REPORT_STATE",
  UPDATE_STATES = "UPDATE_STATES",

  // lookup
  GET_USERS = "GET_USERS",
  GET_LOG_EVENTS = "GET_LOG_EVENTS",

  // log
  GET_LOG = "GET_LOG"
}
