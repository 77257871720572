export class User {

    constructor(u:any){
        this.accessArray = u ? new AccessArray(u.accessArray) : new AccessArray(null);

        this.id = u ? u.user_guid : '',
        this.name = u ? u.username : '',
        this.initials = u ? u.initials : '',
        this.email = u ? u.user : '',
        this.levelId = u ? u.level_guid : '',
        this.levelName = u ? u.level_name : '',
        this.phone = u ? u.phone : '',
        this.active = u ? u.active : false,
        this.unreadCount = u ? u.unreadCount : 0,
        this.token = u ? u.token : ''
    }

    accessArray: AccessArray;
    id: string;
    name: string;
    initials: string;
    email: string;
    levelId: string;
    levelName: string;
    phone: string;
    active: boolean;
    
    unreadCount: number;
    token: string;
}

export class AccessArray {
    constructor(aa: any){
        this.edycja_profilu = rights(aa?.aa.edycja_profilu);
        this.menu_archiwumZlecen = rights(aa?.aa.menu_archiwumZlecen);
        this.menu_klienci = rights(aa?.aa.menu_klienci);
        this.menu_listaZlecen = rights(aa?.aa.menu_listaZlecen);
        this.menu_logSystemu = rights(aa?.aa.menu_logSystemu);
        this.menu_media = rights(aa?.aa.menu_media);
        this.menu_noweZlecenie = rights(aa?.aa.menu_noweZlecenie);
        this.menu_statystykiDruku = rights(aa?.aa.menu_statystykiDruku);
        this.menu_uzytkownicy = rights(aa?.aa.menu_uzytkownicy);
        this.menu_wiadomosci = rights(aa?.aa.menu_wiadomosci);
        this.menu_wycena = rights(aa?.aa.menu_wycena);
        this.zlecenie_drukowanie = rights(aa?.aa.zlecenie_drukowanie);
        this.zlecenie_wykonczenie = rights(aa?.aa.zlecenie_wykonczenie);
        this.zlecenie_zakonczenie = rights(aa?.aa.zlecenie_zakonczenie);
        this.zmiana_hasla = rights(aa?.aa.zmiana_hasla);

        function rights(value:string | null):boolean{
            return value ? value == "1" ? true : false : false;
        }
    }

    edycja_profilu: boolean;
    menu_archiwumZlecen: boolean;
    menu_klienci: boolean;
    menu_listaZlecen: boolean;
    menu_logSystemu: boolean;
    menu_media: boolean;
    menu_noweZlecenie: boolean;
    menu_statystykiDruku: boolean;
    menu_uzytkownicy: boolean;
    menu_wiadomosci: boolean;
    menu_wycena: boolean;
    zlecenie_drukowanie: boolean;
    zlecenie_wykonczenie: boolean;
    zlecenie_zakonczenie: boolean;
    zmiana_hasla: boolean;
}