import { PaginationData } from "../speedprint/models/paginationData";

// Paging 
export function goToPage(page: number, pagination: PaginationData){ 
    pagination.pageNumber = page;
}
export function setItemsPerPage(itemsPerPage: number, pagination: PaginationData){ 
    pagination.itemsPerPage = itemsPerPage;
    pagination.pageNumber = 1;
    console.log('in pagingFn');
}