import { PaginationData } from "./paginationData";

export class PaginatedList<T> {
    constructor(){
        this.items = new Array<T>();
        this.pagination = new PaginationData();
    }

    items: Array<T>;
    pagination: PaginationData;
}