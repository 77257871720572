// import vue stuff
import { createApp, h } from "vue";
import App from "./App.vue";

// import styles
import "bootstrap/scss/bootstrap.scss";
import "bootstrap/dist/js/bootstrap.js";
import "../node_modules/line-awesome/dist/line-awesome/scss/line-awesome.scss";
import "./style.scss";

const app = createApp({
  render: () => h(App),
});

// Store
import { store } from "./store";
app.use(store);

// Router
import router from "./router";
app.use(router);

// Grid loader
import GridLoader from 'vue-spinner/src/GridLoader.vue';
app.component('GridLoader', GridLoader);

// Mitt bus
import Bus from './eventBus';
const emitter = Bus.getBus();
app.provide('emitter', emitter);

// Moment
import moment from "moment";
moment.locale('pl');
app.provide('moment', moment);

// websocket
import { useWebSocket } from '@vueuse/core'

// Piesocket
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PieSocket from 'piesocket-js';
const pieSocket = new PieSocket({
  clusterId: "s9252.fra1",
  apiKey: "375tk6Xbo7bP1pyoKX0RghKzgZsh7RdUCIeFYRPD",
  notifySelf: true
});
app.provide('pieSocket', pieSocket);

app.mount("#app");
